import React from 'react';
import { Package } from '../Classes/Package';
import Layout from './Layout/layout';
import Documents from '../Views/Website/Documents/Documents';

const DocumentsPage: React.FC = (props: any) => {
  const Packages: Package[] = props.pageContext.Packages;

  return (
    <Layout>
      {Packages ? <Documents location={props.location} PackagesData={Packages} /> : <></>}
    </Layout>
  );
};

export default DocumentsPage;
