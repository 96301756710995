// extracted by mini-css-extract-plugin
export var Active = "Documents-module--Active--c9af4";
export var Advert = "Documents-module--Advert--c92df";
export var AdvertImage = "Documents-module--AdvertImage--76d37";
export var AdvertInfo = "Documents-module--AdvertInfo--f3a50";
export var Adverts = "Documents-module--Adverts--b78c7";
export var Documents = "Documents-module--Documents--18249";
export var DocumentsInner = "Documents-module--DocumentsInner--460e3";
export var Filter = "Documents-module--Filter--ccfca";
export var FilterButton = "Documents-module--FilterButton--9970c";
export var Loading = "Documents-module--Loading--2ef47";
export var Message = "Documents-module--Message--785fa";
export var Nav = "Documents-module--Nav--a197c";
export var NavInner = "Documents-module--NavInner--142be";
export var Search = "Documents-module--Search--0aace";
export var SearchInput = "Documents-module--SearchInput--c6dca";
export var Section = "Documents-module--Section--a8957";
export var SectionHeader = "Documents-module--SectionHeader--8659f";
export var SectionInner = "Documents-module--SectionInner--a8e2f";
export var Show = "Documents-module--Show--62863";