import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Package } from '../../../Classes/Package';
import { Document as DocProps } from "../../../Classes/Document";
import DocumentItem from "../../../components/Document/Document";
import * as styles from './Documents.module.scss';
import { faFilter, faMinus, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Section } from '../../../Classes/Section';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Helmet } from 'react-helmet';
import { Loading } from '../../../components/Loading/Loading';
import { navigate } from 'gatsby';

interface Props {
  PackagesData: Package[];
  location: any;
}

const Documents: React.FC<Props> = ({ PackagesData, location }) => {
  const [search, setSearch] = useState<string>("");
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [packageFilters, setPackageFilters] = useState<number[]>([] as number[]);
  const [sectionFilters, setSectionFilters] = useState<number[]>([] as number[]);
  const [collapseSections, setCollapseSection] = useState<number[]>([] as number[]);
  const [Packages, setPackages] = useState<Package[]>([] as Package[]);

  useEffect(() => {
    if (PackagesData) {
      let docs: DocProps[] = [] as DocProps[];
      let Sections: Section[] = [] as Section[];

      setPackageFilters(JSON.parse(JSON.stringify(PackagesData)).map((x: Package) => x.ID));

      JSON.parse(JSON.stringify(PackagesData)).map((packageData: Package) => {
        packageData.Documents.map((document: DocProps) => {
          docs.push({ ...document, Package: { ...packageData, Documents: [] as DocProps[] } });
        })
      })

      docs.map((document: DocProps) => {
        if (!Sections.find((section: Section) => section.ID === document.SectionID)) {
          document.Section.Documents = docs.filter(x => x.SectionID === document.SectionID);
          Sections.push(document.Section);
        }
      })

      setSectionFilters(Sections.map((section: Section) => section.ID));
      setSections(Sections);
      setPackages(PackagesData);
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    Search(location);
  }, [location.search])

  const Search = (Location: any) => {
    let search = Location.search;
    if (search) {
      let params: string[] = search.split("&");
      let index: number = params.findIndex(x => x.toLowerCase().includes("search="));
      if (index >= 0)
        setSearch(params[index].split("=")[1]);
    } else {
      setSearch("");
    }
  }

  const TogglePackageFilter = (IDs: number[]) => {
    let filters = JSON.parse(JSON.stringify(packageFilters));

    if (IDs.length > 1) {
      if (filters.length < (IDs.length / 2))
        filters = IDs;
      else
        filters = [];
    } else {
      IDs.map((ID) => {
        let fil = packageFilters.findIndex(x => x === ID);
        if (fil >= 0)
          filters.splice(fil, 1);
        else
          filters.push(ID);
      });
    }

    setPackageFilters(filters);
  }

  const ToggleSectionFilter = (IDs: number[]) => {
    let filters = JSON.parse(JSON.stringify(sectionFilters));

    if (IDs.length > 1) {
      if (filters.length < (IDs.length / 2))
        filters = IDs;
      else
        filters = [];
    } else {
      IDs.map((ID) => {
        let fil = sectionFilters.findIndex((sectionID: number) => sectionID === ID);
        if (fil >= 0)
          filters.splice(fil, 1);
        else
          filters.push(ID);
      })
    }

    setSectionFilters(filters);
  }

  const ToggleCollapseSection = (ID: number) => {
    let sections = JSON.parse(JSON.stringify(collapseSections));
    let coll = collapseSections.findIndex(x => x === ID);
    if (coll >= 0) {
      sections.splice(coll, 1);
    } else {
      sections.push(ID);
    }
    setCollapseSection(sections);
  }

  const GetSectionCount = (ID: number) => {
    let count: number = 0;

    if (Packages)
      Packages.map(p => {
        p.Documents.map(d => {
          if (d.SectionID === ID)
            count++;
        })
      })

    return count;
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Documents</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />
    <Breadcrumb Trail={[
      { To: "/Packages", Text: "Packages" },
      { To: ``, Text: "Documents" }
    ]} />

    {loading ? <div className={styles.Loading}><Loading /></div> : <>
      <div className={styles.Search}>
        <div className={styles.SearchInput}>
          <i><FontAwesomeIcon icon={faSearch} /></i>
          <input type="search" placeholder="Search..." value={search} onChange={(e) => navigate(`?search=${e.target.value}`)} />
        </div>
      </div>

      <div className={styles.Documents}>
        <button className={`${styles.FilterButton} `} onClick={() => setShowFilter(!showFilter)}>
          <i>
            <FontAwesomeIcon icon={faFilter} />
          </i>
          Filter

        </button>

        <ul className={`${styles.Filter} ${showFilter ? styles.Show : ""}`}>
          <li>
            <p>Package</p>
            <input checked={packageFilters.length === Packages.length} type="checkbox" onClick={() => TogglePackageFilter(Packages.map((p) => { return p.ID }))} />
          </li>
          {
            Packages.filter(p => p.ID < 4).map(pack => {
              return <li key={`package-${pack.ID}`}>
                <label>
                  <input checked={packageFilters.includes(pack.ID)} type="checkbox" onClick={() => TogglePackageFilter([pack.ID])} />
                  <span>{pack.Title} ({pack.Documents.length})</span>
                </label>
              </li>
            })
          }
          <li>
            <p>Category</p>
            <input checked={sectionFilters.length === sections.length} type="checkbox" onClick={() => ToggleSectionFilter(sections.map((s) => { return s.ID }))} />
          </li>
          {
            sections.map(sec => {
              return <li key={`section-${sec.ID}`}>
                <label>
                  <input checked={sectionFilters.includes(sec.ID)} type="checkbox" onClick={() => ToggleSectionFilter([sec.ID])} />
                  <span>{sec.Title} ({GetSectionCount(sec.ID)})</span>
                </label>
              </li>
            })
          }
        </ul>

        <div className={styles.DocumentsInner}>
          <div className={styles.SectionHeader}>
            <button type="button" onClick={() => setCollapseSection([])}>Uncollapse All</button>
            <button type="button" onClick={() => setCollapseSection(sections.map(x => x.ID))}>Collapse All</button>
          </div>
          {sections.map(sec => {
            return <div className={`${styles.Section} ${sectionFilters.includes(sec.ID) ? styles.Show : ""}`} key={`sectionblock-${sec.ID}`}>
              <div className={styles.SectionInner}>
                {sec.Documents.filter(d => d.Title.toLowerCase().includes(search)).length ? <>
                  <h1 onClick={() => ToggleCollapseSection(sec.ID)}><span>{sec.Title} ({sec.Documents.length})</span><i><FontAwesomeIcon icon={collapseSections.includes(sec.ID) ? faPlus : faMinus} /></i></h1>
                  {!collapseSections.includes(sec.ID) ? <ul>
                    {
                      sec.Documents.length ? sec.Documents.map((doc, i) => {
                        return <li key={doc.ID} className={(doc.Title.toLowerCase().includes(search) && packageFilters.find(x => x === doc.PackageID)) ? styles.Show : ""}>
                          <DocumentItem DocData={doc} isPortal={false} />
                        </li>
                      }) : <></>
                    }
                  </ul> : <></>}
                </> : <></>}
              </div>
            </div>
          })}
        </div>
      </div>
    </>}
  </>);
};

export default Documents;
